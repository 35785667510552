import React, { useState } from 'react';
import './Testimonial.css';
import images from "../../../Images";


const Testimonial = () => {



    const [animationPaused, setAnimationPaused] = useState(false);

    const handleMouseEnter = () => {
        setAnimationPaused(true);
    };

    const handleMouseLeave = () => {
        setAnimationPaused(false);
    };

    const [animationPaused2, setAnimationPaused2] = useState(false);

    const handleMouseEnter2 = () => {
        setAnimationPaused2(true);
    };

    const handleMouseLeave2 = () => {
        setAnimationPaused2(false);
    };


    return (
        <section id='Testimonial' className='testimonial vertical-space'>
            <div className='titleDiv' >
                <h2 className='section-title'>Customer Testimonials</h2>
                <p className='sub-title'>See what people are saying.</p>
            </div>
            <div className='testimonial-inner'>
                <div className='container-fluid'>
                    <div class="slider" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <div class="slide-track" style={{ animationPlayState: animationPaused ? 'paused' : 'running' }}>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div class="slider slider-rev" onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                        <div class="slide-track" style={{ animationPlayState: animationPaused2 ? 'paused' : 'running' }}>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User1} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide">
                                <div className='test-box'>
                                    <div class="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={images.User2} alt='User Img' />
                                        <p className='userName'>Lorem Ipsum</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>
                                            Sed sed dolor tempus sapien lobortis auctor.
                                            Vestibulum a mattis metus, ac vulputate risus.
                                            Duis lacinia erat eget vulputate hendrerit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial;