import React, { useRef, useState, useEffect } from 'react';
import BreadCrumb from '../Breadcrumb_Component/BreadCrumb';
import { useNavigate } from 'react-router-dom';
import './doodleMain.css';

const DoodleMain = () => {
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);
    const [showCanvas, setShowCanvas] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
    const [imageStart, setImageStart] = useState({ width: 500, height: 500 });
    const navigate = useNavigate();

    useEffect(() => {
        if (!showCanvas || !canvasRef.current) return;

        const canvas = canvasRef.current;

        const handleMouseMove = (event) => {
            if (isDragging) {
                const newWidth = imageStart.width + (event.clientX - mouseStart.x);
                const newHeight = imageStart.height + (event.clientY - mouseStart.y);
                setWidth(newWidth);
                setHeight(newHeight);
                if (image) {
                    drawImage(image, newWidth, newHeight);
                }
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);
        canvas.addEventListener('mouseleave', handleMouseUp);

        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
            canvas.removeEventListener('mouseleave', handleMouseUp);
        };
    }, [isDragging, mouseStart, imageStart, image, showCanvas]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    setImage(img);
                    drawImage(img, width, height);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const drawImage = (img, width, height) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
    };

    const handleResize = (newWidth, newHeight) => {
        setWidth(newWidth);
        setHeight(newHeight);
        if (image) {
            drawImage(image, newWidth, newHeight);
        }
    };

    const handleResetSize = () => {
        setWidth(500);
        setHeight(500);
        if (image) {
            drawImage(image, 500, 500);
        }
    };

    // eslint-disable-next-line
    const handleResetImage = () => {
        setImage(null);
    };

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setMouseStart({ x: event.clientX, y: event.clientY });
        setImageStart({ width, height });
    };

    const handleUploadClick = () => {
        setShowCanvas(true);
    };

    const handleDrawClick = () => {
        navigate('/DoodleDraw');
    };

    return (
        <>
            <BreadCrumb currentPage={'Doodle'} pageTitle={'Draw your Doodles'} />
            {!showCanvas ? (
                <div className="horizontal-space vertical-space button-options my-flex-center gap-3">
                    <button className='more-btn' onClick={handleUploadClick}>Upload your doodle</button>
                    <button className='more-btn' onClick={handleDrawClick}>Draw your doodle</button>
                </div>
            ) : (
                <div id='doodleMain' className='horizontal-space vertical-space'>
                    <div className='gap-3 d-flex justify-content-center align-items-end'>
                        <input className='imgUpload form-control'
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                        />
                    </div>
                    <div className='d-flex justify-content-between align-items-end'>
                        <div className='d-flex gap-3'>
                            <label>
                                Width:
                                <input
                                    type="number"
                                    value={width}
                                    onChange={(e) => handleResize(Number(e.target.value), height)}
                                    className='form-control'
                                />
                            </label>
                            <label>
                                Height:
                                <input
                                    type="number"
                                    value={height}
                                    onChange={(e) => handleResize(width, Number(e.target.value))}
                                    className='form-control'
                                />
                            </label>
                        </div>
                        <div className='d-flex gap-3'>
                            {/* <button className='form-control' onClick={handleResetImage}>
                                <p>Reset Image</p>
                            </button> */}
                            <button className='form-control' onClick={handleResetSize}>
                                <p>Reset Size</p>
                            </button>
                        </div>
                    </div>
                    <div className='canvasDiv my-flex-center flex-column'>
                        <canvas
                            ref={canvasRef}
                            onMouseDown={handleMouseDown}
                            style={{ border: '1px solid black', cursor: 'nwse-resize' }}
                        ></canvas>
                    </div>
                    <div className="button-options my-flex-center justify-content-end gap-3">
                        <button className='more-btn'>Send</button>
                        <button className='br-btn-2' onClick={handleDrawClick}>Want to Draw your own doodle?</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default DoodleMain;
