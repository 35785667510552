// Loader.js
import React from 'react';
import './index.css';

const Loader = () => {
    return (
        <div className="loader d-flex justify-content-center align-items-center">
            <div className="spinner"></div>
            <img src={Image.logo} alt='' />
        </div>
    );
};

export default Loader;
