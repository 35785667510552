import { useEffect } from 'react';

const DoodleDraw = () => {
    useEffect(() => {
        window.location.href = '/out/index.html';
    }, []);

    return null;
};

export default DoodleDraw;
