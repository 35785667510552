import React from "react";
import './Donate.css';
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import images from "../../Images";

export default function Donate() {

    const removeDoodleId = () => {
        localStorage.removeItem('doodleId');
    };
    
    return (
        <>
            <BreadCrumb className="m-0" currentPage="Support Us" pageTitle="Donate with Purpose: Fuel Our Mission" />
            <section className="donate horizontal-space vertical-space">
                <div className="container top-heading">
                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                            <p className="text-center mb-4 w-75">Help us build our background by leaving your mark!</p>
                            <div className="d-flex justify-content-center align-content-center gap-3 br-fc">
                                <a className="more-btn" href="/nextjs-app">Create your Doodle</a>
                                <a className="more-btn" href="/donate-form" onClick={removeDoodleId}>Make a Donation</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container donate-works">
                    <h4 className="section-title-2 text-center">How it Works?</h4>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-num">1</p>
                                <p className="step-title">Create your Doodle</p>
                                <p className="step-desc text-center">Leave your mark on SoApp
                                    Become a part of SoApp’s future</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-num">2</p>
                                <p className="step-title">Donate and Submit</p>
                                <p className="step-desc text-center">
                                    Your submission will be reviewed
                                    And set to soapp’s standards and rules
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-num">3</p>
                                <p className="step-title">Recieve confirmation</p>
                                <p className="step-desc text-center">
                                    Your doodle become part of SoApp’s
                                    Chat background!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="legal" className="donate-view">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="legal-txt d-flex flex-column justify-content-center align-items-start">
                                    <p><b>RULE BOOK:</b></p>
                                    <ol>
                                        <li>Draw a doodle, signature, mark. Keep it simple!</li>
                                        <li>No photos, politics, sex, drugs, brands, private information & offensive imagery</li>
                                        <li>All submissions will be adapted to Grey-scale or black and white lines (TBD)</li>
                                        <li>All submissions will be subject to approval by the team.</li>
                                        <li>All approved submissions will be used to create SoApp’s unique chat background.</li>
                                        <li>You can import an image in SVG format</li>
                                        <li>Size of donation will impact size of your mark on the background (TBD)</li>
                                    </ol>
                                    <p><b>MOST IMPORTANTLY</b>, have fun and thank you for supporting the creation of SoApp! We ♥ you!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container donate-view">
                    <h1 className="section-title">Leave your mark</h1>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="donate-preview">
                                <img src={images.DrawSS} alt="Draw" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex gap-3 justify-content-center donate-mt">
                            <a className="more-btn" href="/nextjs-app">Create your Doodle</a>
                            <a className="more-btn" href="/donate-form" onClick={removeDoodleId}>Make a Donation</a>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}