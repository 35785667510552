import logo from './assets/images/logo.svg';
import playstore from './assets/images/playstore.svg';
import applestore from './assets/images/applestore.svg';
import playstore2 from './assets/images/playstore2.svg';
import applestore2 from './assets/images/applestore2.svg';
import welcomeMob from './assets/images/welcomeMob.png';
import Launch1 from './assets/images/versatile-Chat-suites.png';
import Launch2 from './assets/images/Group-payments.png';
import Launch3 from './assets/images/event-organisation.png';
import Vision from './assets/images/vision.png';
import ChatWall from './assets/images/ChatWall.png';
import GroupChat from './assets/images/GroupChat.png';
import PinnedMessaging from './assets/images/PinnedMessaging .png';
import ComplexMessaging from './assets/images/ComplexMessaging.png';
import BodyBG from './assets/images/bodyBG.jpg';
import Feature from './assets/images/Feature.png';
import User1 from './assets/images/user1.png';
import User2 from './assets/images/user2.png';
import Blog1 from './assets/images/blog1.png';
import Blog2 from './assets/images/blog2.png';
import Blog3 from './assets/images/blog3.png';
import Blog4 from './assets/images/blog4.png';
import playstore3 from './assets/images/playstore3.svg';
import applestore3 from './assets/images/applestore3.svg';
import logo2 from './assets/images/logo2.svg';
import Facebook from './assets/images/facebook.svg';
import Instagram from './assets/images/instagram.svg';
import Twitter from './assets/images/twitter.svg';
import LinkedIn from './assets/images/linkedin.svg';
import DrawSS from './assets/images/next-bg.png';
import LaunchBG from './assets/images/launch-img.png';

//About Us Images
import visionAabout from './assets/images/vision-about.png';
import Calendar from './assets/images/Calendar.png';
import Team1 from './assets/images/team1.png';
import Team2 from './assets/images/team2.png';
import Team3 from './assets/images/team3.png';
import TwitterBlue from './assets/images/TwitterBlue.svg';
import LinkedinBlue from './assets/images/LinkedinBlue.svg';
import Gdpr from './assets/images/gdpr.png';
import Lock from './assets/images/lock.png';

// Blog Images
import BlogMain1 from './assets/images/blogMain1.png';
import BlogMain2 from './assets/images/blogMain2.png';
import BlogMain3 from './assets/images/blogMain3.png';
import BlogMain4 from './assets/images/blogMain4.png';
import BlogSingle from './assets/images/blogSingle.png';

//Pagination
import PaginationPrev from './assets/images/paginationPrev.svg'
import PaginationNext from './assets/images/paginationNext.svg'

//Features
import Feature1 from './assets/images/Feature1.png'
import Feature2 from './assets/images/Feature2.png'
import Feature3 from './assets/images/Feature3.png'
import Feature4 from './assets/images/Feature4.png'
import Feature5 from './assets/images/Feature5.png'

//Faq
import Plus from './assets/images/plus-square.svg'
import Minus from './assets/images/minus-square.svg'

//BG
import StarPlatinum from './assets/video/starplatinum.webm'
// import Comp from './assets/video/Comp.mp4'
// import Resize from './assets/video/resize.mp4'
import MainWall from './assets/video/MainWall.webm'
import GroupChatVid from './assets/video/groupChat.webm'
import EventOrganisation from './assets/video/eventOrganisation.webm'
import PinnedMessage from './assets/video/PinnedMessage.webm'
import VersatileChat from './assets/video/VersatileChat.webm'
import ComplexMessage from './assets/video/ComplexMessage.webm'
import Flip from './assets/video/flip.webm'
import congoBg from './assets/video/congoBg.mp4'

//Menu
import Menu from './assets/images/Menu.svg'
import Close from './assets/images/Cancel.svg'

//Features
import moneyTalks from './assets/images/money-talks.svg'
import chatApi from './assets/images/chat-api.svg'
import soCreate from './assets/images/so-create.svg'
import gdprDataClones from './assets/images/gdpr-data-clones.svg'
import soPress from './assets/images/so-press.svg'

const images = {
  logo: logo,
  playstore: playstore,
  applestore: applestore,
  playstore2: playstore2,
  applestore2: applestore2,
  welcomeMob: welcomeMob,
  Launch1: Launch1,
  Launch2: Launch2,
  Launch3: Launch3,
  Vision: Vision,
  ChatWall: ChatWall,
  GroupChat: GroupChat,
  PinnedMessaging: PinnedMessaging,
  ComplexMessaging: ComplexMessaging,
  BodyBG: BodyBG,
  Feature: Feature,
  User1: User1,
  User2: User2,
  Blog1: Blog1,
  Blog2: Blog2,
  Blog3: Blog3,
  Blog4: Blog4,
  playstore3: playstore3,
  applestore3: applestore3,
  logo2: logo2,
  Facebook: Facebook,
  Instagram: Instagram,
  Twitter: Twitter,
  LinkedIn: LinkedIn,
  visionAabout: visionAabout,
  Calendar: Calendar,
  Team1: Team1,
  Team2: Team2,
  Team3: Team3,
  TwitterBlue: TwitterBlue,
  LinkedinBlue: LinkedinBlue,
  BlogMain1: BlogMain1,
  BlogMain2: BlogMain2,
  BlogMain3: BlogMain3,
  BlogMain4: BlogMain4,
  PaginationPrev: PaginationPrev,
  PaginationNext: PaginationNext,
  BlogSingle: BlogSingle,
  Feature1: Feature1,
  Feature2: Feature2,
  Feature3: Feature3,
  Feature4: Feature4,
  Feature5: Feature5,
  Plus: Plus,
  Minus: Minus,
  StarPlatinum: StarPlatinum,
  Menu: Menu,
  Close: Close,
  // Comp: Comp,
  // Resize: Resize,
  MainWall: MainWall,
  EventOrganisation: EventOrganisation,
  GroupChatVid: GroupChatVid,
  PinnedMessage: PinnedMessage,
  VersatileChat: VersatileChat,
  ComplexMessage: ComplexMessage,
  Flip: Flip,
  DrawSS: DrawSS,
  congoBg: congoBg,
  Lock: Lock,
  Gdpr: Gdpr,
  LaunchBG: LaunchBG,
  moneyTalks: moneyTalks,
  chatApi: chatApi,
  soCreate: soCreate,
  gdprDataClones: gdprDataClones,
  soPress: soPress
};

export default images;
