import React from "react";
import './Feature.css';
import images from "../../../Images";

const Feature = () => {
    return (
        <section id='Feature' className='feature inner-padding mt vertical-space horizontal-space section-bg'>
            <div class="blur-overlay"></div>
            <div className='feature-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-7 d-flex align-items-center justify-content-center'>
                            <div className='titleDiv'>
                                <h2 className='section-title'>Our Upcoming Feature:
                                    Trip marketplace</h2>
                                <p className='sub-title'>Go on a trip with friends then sell that experience.
                                    Live the experience curated by a member of
                                    our community</p>
                            </div>
                        </div>

                        <div className='col-5'>
                            <div className="feature-img-home">
                                <img src={images.Feature} alt="Feature" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feature;