import React from "react";
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import images from "../../Images";

export default function Gdpr() {

    return (
        <>
            <BreadCrumb currentPage="GDPR" pageTitle="SoApp makes GDPR work With & For you" />
            <section id="GDPR" className="horizontal-space vertical-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="gdpr-img">
                                <img src={images.Gdpr} alt="gdpr" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container donate-works">
                    <h4 className="section-title-2 text-center">
                        <img className="gdpr-lock" src={images.Lock} alt="Lock" /> Your Data Is Working FOR You
                    </h4>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-title text-left">Minting Data Clones</p>
                                <p className="step-desc">
                                    SoApp gives you the tools to mint Data Clones <br />
                                    Your Control
                                    <ul>
                                        <li>The Quantity</li>
                                        <li>The Data</li>
                                        <li>The Price</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-title text-left">Buying Data Clones</p>
                                <p className="step-desc">
                                    By buying a data clone, you can experience SoApp through the eyes to another!
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="step-box section-bg">
                                <p className="step-title text-left">Selling Data Clones</p>
                                <p className="step-desc">
                                    Start Earning! <br />
                                    Your Control:
                                    <ul>
                                        <li>The Private Information</li>
                                        <li>What you Sell</li>
                                        <li>Who Buys</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}