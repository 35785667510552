import React, { useState, useEffect } from "react";
import './DonateForm.css';
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import images from "../../Images";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

const DonateForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const cardElementOptions = {
        style: {
            base: {
                color: '#fff', 
                fontSize: '16px', 
                '::placeholder': {
                    color: '#ccc',
                },
            },
            invalid: {
                color: '#e5424d', 
            },
        },
    };

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("");

    const [selectedPrice, setSelectedPrice] = useState("100");
    const [showOtherAmount, setShowOtherAmount] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        reason: "",
        amount: "",
        message: "",
        otheramt: ""
    });


    useEffect(() => {
        setShowOtherAmount(selectedPrice === 'otheramount');
    }, [selectedPrice]);

    const handlePriceChange = (event) => {
        console.log('Selected price:', event.target.value);
        setSelectedPrice(event.target.value);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [id]: value }));
    };

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    useEffect(() => {
        const amount = selectedPrice === "otheramount" ? formData.otheramt : selectedPrice;
        console.log('Selected Amount:', amount);
        fetch("https://soapp.hodemoserver.in/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ amount: parseFloat(amount) }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, [selectedPrice, formData.otheramt]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const amount = selectedPrice === "otheramount" ? formData.otheramt : selectedPrice;
        console.log('Selected Amount:', amount);

        try {
            const donationResponse = await axios.post("https://soapp.hodemoserver.in/save-donation", {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                reason: formData.reason,
                amount,
                message: formData.message
            });

            const donationId = donationResponse.data.donationId;

            const receivedDoodleIdFromClient = localStorage.getItem('doodleId');
            const doodleId = receivedDoodleIdFromClient;
            console.log('Received DoodleId From Client:', receivedDoodleIdFromClient);

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: formData.name,
                        email: formData.email,
                    },
                },
            });

            if (error) {
                setMessage("Payment Failed");
                console.error("Payment Failed", error.message);

                await axios.post('https://soapp.hodemoserver.in/delete-doodle', { doodleId });

            } else {
                if (paymentIntent.status === "succeeded") {

                    console.log("Payment successful!");
                    console.log("Donation saved successfully!");

                    // Calling /update-donation API
                    await axios.post("https://soapp.hodemoserver.in/update-donation", { donationId, doodleId });
                    console.log('Database Updated Successfully');

                    setFormSubmitted(true);

                    // Calling /send-email API
                    await axios.post('https://soapp.hodemoserver.in/send-email', { email: formData.email, doodleId });
                    console.log('Email sent successfully');

                } else {
                    console.error('Error Updating Database');
                }
            }
        } catch (error) {
            setMessage("An unexpected error occurred.");
            console.error("Error", error);
        }
        setIsLoading(false);
    };

    return (
        <>
            <BreadCrumb currentPage={'Support Us'} pageTitle={'Donate with Purpose: Fuel Our Mission'} />
            <section id="ContactUs" className="ContactUs horizontal-space vertical-space df">
                <div className={`container donate-form-container ${formSubmitted ? 'd-none' : ''}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-bg inner-padding enq">
                                <div className="blur-overlay"></div>
                                <h2 className="section-title-2">Please Fill Information Below</h2>
                                <form className="row" onSubmit={handleSubmit}>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" value={formData.name} onChange={handleInputChange} placeholder="Enter your full name" className="form-control" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" value={formData.email} onChange={handleInputChange} placeholder="Enter email" className="form-control" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="num">Phone Number</label>
                                        <input type="text" id="phone" value={formData.phone} onChange={handleInputChange} placeholder="Enter phone" className="form-control" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="reason">How Did You Learn About Our Organization?</label>
                                        <select id="reason" name="reason" value={formData.reason} onChange={handleInputChange} className="form-control" aria-invalid="false" tabIndex="10" required>
                                            <option value="">Select Options</option>
                                            <option value="Alpha 1">Alpha 1</option>
                                            <option value="Alpha 2">Alpha 2</option>
                                            <option value="Alpha 3">Alpha 3</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12 br-df-p">
                                        <label htmlFor="amt-res" className="mb-2">Pledge Amount</label>
                                        <div className="radio-toolbar br-amount">
                                            <span id="amt-res">
                                                <input
                                                    type="radio"
                                                    id="amount10"
                                                    name="price"
                                                    value="10"
                                                    checked={selectedPrice === '10'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount10"><i className="fa-solid fa-dollar-sign"></i> 10</label>
                                                <input
                                                    type="radio"
                                                    id="amount20"
                                                    name="price"
                                                    value="20"
                                                    checked={selectedPrice === '20'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount20"><i className="fa-solid fa-dollar-sign"></i> 20</label>
                                                <input
                                                    type="radio"
                                                    id="amount30"
                                                    name="price"
                                                    value="30"
                                                    checked={selectedPrice === '30'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount30"><i className="fa-solid fa-dollar-sign"></i> 30</label>
                                                <input
                                                    type="radio"
                                                    id="amount40"
                                                    name="price"
                                                    value="40"
                                                    checked={selectedPrice === '40'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount40"><i className="fa-solid fa-dollar-sign"></i> 40</label>
                                                <input
                                                    type="radio"
                                                    id="amount50"
                                                    name="price"
                                                    value="50"
                                                    checked={selectedPrice === '50'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount50"><i className="fa-solid fa-dollar-sign"></i> 50</label>
                                                <input
                                                    type="radio"
                                                    id="amount75"
                                                    name="price"
                                                    value="75"
                                                    checked={selectedPrice === '75'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount75"><i className="fa-solid fa-dollar-sign"></i> 75</label>
                                                <input
                                                    type="radio"
                                                    id="amount100"
                                                    name="price"
                                                    value="100"
                                                    checked={selectedPrice === '100'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="amount100"><i className="fa-solid fa-dollar-sign"></i> 100</label>
                                            </span>
                                            <span id="ot">
                                                <input
                                                    type="radio"
                                                    id="otheramount"
                                                    name="price"
                                                    value="otheramount"
                                                    checked={selectedPrice === 'otheramount'}
                                                    onChange={handlePriceChange}
                                                />
                                                <label htmlFor="otheramount">Other Amount</label>
                                            </span>
                                        </div>
                                        {showOtherAmount && (
                                            <div className="col-12 col-md-11">
                                                <div className="form-group box-other-amount" id="checkother-amt">
                                                    <input type="number" id="otheramt" name="otheramt" value={formData.otheramt || ''} onChange={handleInputChange} className="br-hidden-input form-control"
                                                        placeholder="Enter other amount" required aria-required="true" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12 br-df-p">
                                        <label htmlFor="message">Message</label>
                                        <textarea id="message" rows='3' value={formData.message} onChange={handleInputChange} placeholder="Write your message here" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group col-md-12 br-df-p">
                                        <label htmlFor="card-element">Credit or Debit Card</label>
                                        <CardElement id="card-element" options={cardElementOptions} className="form-control" />
                                    </div>
                                    <div className="form-group com-md-12  br-df-p d-flex">
                                        <button type="submit" className="more-btn" disabled={isLoading || !stripe || !elements}>
                                            <span id="button-text">
                                                {isLoading ? <div className="wait" aria-readonly>Processing</div> : "Submit"}
                                            </span>
                                        </button>
                                        {message && <div id="payment-message">{message}</div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`container donate-thankyou-container ${!formSubmitted ? 'd-none' : ''}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="congo-box">
                                <video autoPlay loop muted className="congo-vid">
                                    <source src={images.congoBg} type="video/mp4" />
                                </video>
                                <p className="congo-title text-center m-0">Thank you for your generous donation!<br />
                                    🎉Congratulations on creating a doodle!🎨</p>
                                <p className="text-center m-0">Your artistic expression is not only beautiful but also impactful. Every stroke of your pen contributes to positive change. Thank you for turning creativity into kindness!</p>
                                <p className="text-center m-0">Kindly review your inbox at <a className="congo-link" href='/'>{formData.email}</a> to access the doodle image. Thank you!</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default DonateForm;