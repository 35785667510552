import React, { useState } from "react";
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import Pagination from "../Pagination_Component/Pagination";
import './Testimonials.css';
import images from "../../Images";

const testimonialsData = Array.from({ length: 32 }, (_, i) => ({
    id: i + 1,
    userImg: images.User1,
    userName: `User Name ${i + 1}`,
    comment: `Sed sed dolor tempus sapien lobortis auctor. Vestibulum a mattis metus, ac vulputate risus. Duis lacinia erat eget vulputate hendrerit.`
}));

const Testimonials = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const testimonialsPerPage = 8;

    const indexOfLastTestimonial = currentPage * testimonialsPerPage;
    const indexOfFirstTestimonial = indexOfLastTestimonial - testimonialsPerPage;

    const sortedNewsData = testimonialsData.sort((a, b) => b.id - a.id);
    
    const currentTestimonials = sortedNewsData.slice(indexOfFirstTestimonial, indexOfLastTestimonial);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <BreadCrumb currentPage="Testimonials" pageTitle="Words from Our Delighted Customers" />
            <section id="Testimonials" className="testimonials horizontal-space vertical-space">
                <div className="testimonials-inner container d-flex">
                    <div className="row">
                        {currentTestimonials.map((testimonial, index) => (
                            <div className="col-6" key={index}>
                                <div className='test-box'>
                                    <div className="blur-overlay"></div>
                                    <div className='user'>
                                        <img src={testimonial.userImg} alt='User Img' />
                                        <p className='userName'>{testimonial.userName}</p>
                                    </div>
                                    <div className='userComment'>
                                        <p>{testimonial.comment}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Pagination
                        newsPerPage={testimonialsPerPage}  // Ensure this is consistent with your Pagination component
                        totalNews={testimonialsData.length} // Ensure this is consistent with your Pagination component
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
            </section>
        </>
    );
}

export default Testimonials;
