import React, { useState } from "react";
import './ContactUs.css';
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, message } = formData;

        console.log('formData - ', formData);

        try {
            const response = await fetch('https://soapp.hodemoserver.in/contact-us-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email, message })
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Your message has been sent successfully!');
                setErrorMessage('');
                setFormData({ name: '', email: '', message: '' }); // Reset form
            } else {
                setErrorMessage('There was an error sending your message. Please try again.');
                setSuccessMessage('');
                console.log(data.error);
            }
        } catch (error) {
            setErrorMessage('There was an error sending your message. Please try again.');
            setSuccessMessage('');
            console.error(error);
        }
    };

    return (
        <>
            <BreadCrumb currentPage={'Contact Us'} pageTitle={'Get in touch and let us know how we can help.'} />
            <section id="ContactUs" className="ContactUs horizontal-space vertical-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <div className="section-bg inner-padding enq">
                                <div className="blur-overlay"></div>
                                <h2 className="section-title-2">General Enquiries</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" name="name" placeholder="Enter Name" className="form-control" value={formData.name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Enter Email" className="form-control" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea id="message" name="message" rows='3' placeholder="Write your message here" className="form-control" value={formData.message} onChange={handleChange} required></textarea>
                                    </div>
                                    <button type="submit" className="more-btn">Send Message</button>
                                </form>
                                {successMessage && <p className="success-message">{successMessage}</p>}
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUs;
    