import React from 'react';
import './BlogSingle.css';
import images from '../../../Images';

const BlogSingle = () => {
    return (
        <section id="BlogSingle" className='blogsingle horizontal-space vertical-space'>
            <div className='blog-top'>
                <div className='container'>
                    <h2 className='section-title'>Vestibulum a mattis metus ac vulputate risus.</h2>
                    <div className='row'>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='cat-info'>
                                <p>Category</p>
                                <span>Category 01</span>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='cat-info'>
                                <p>Published On</p>
                                <span>March 03, 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blog-middle container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='blogSingleImgDiv'>
                            <img className='blogSingleImg' src={images.BlogSingle} alt="Blog" />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='blogTxt'>
                            <p>
                                Donec rutrum ligula sed enim venenatis lacinia. Ut in orci eget lacus elementum pharetra id viverra metus. Duis laoreet aliquam nisl, sit amet laoreet nunc venenatis id. In eget ante metus. Donec quis sapien maximus, porta nunc ac, faucibus nulla. Suspendisse ut tristique erat, ut pulvinar ipsum. Integer lacus ante, auctor quis nunc nec, suscipit rutrum nisl. Donec elementum, risus sit amet aliquam malesuada, dui risus ullamcorper magna, a rutrum risus risus at enim. Curabitur blandit eleifend dolor, et volutpat nisl blandit eu. Nulla ornare tortor urna, in molestie dui commodo sit amet. Sed vitae nulla odio. Etiam facilisis in erat viverra porttitor. Suspendisse tempor mi a odio vulputate, sit amet facilisis leo volutpat. Nunc vitae lectus id est feugiat finibus sit amet consectetur quam. Duis sit amet egestas dolor.
                            </p>
                            <p>
                                Nam tincidunt leo in velit suscipit, vitae feugiat neque tristique. Nulla bibendum tempus diam, sed bibendum mi convallis id. Aenean nec nisi dolor. Curabitur in lorem sed est aliquet rhoncus ut vel odio. In hac habitasse platea dictumst. Sed ac ipsum vitae ante semper consequat. Donec et ante ligula. Aenean porta nibh neque, a euismod purus blandit in. Suspendisse in bibendum mauris. Nunc accumsan quam quis condimentum ultrices. Sed risus lectus, porttitor vel hendrerit sed, commodo nec dolor. Vivamus auctor consequat dolor non lacinia. Nulla at leo eget lorem finibus tempus sed id diam. Vestibulum ultricies fringilla nulla, nec fermentum mauris iaculis vitae. Curabitur bibendum dui quis nisi venenatis efficitur.
                            </p>
                            <p>
                                Aenean in purus nunc. Mauris facilisis pulvinar mi, nec dictum quam ullamcorper non. Maecenas et lectus tincidunt, tristique ex a, suscipit sem. Vivamus consectetur, metus mollis cursus euismod, eros lacus sagittis urna, at venenatis lorem neque non erat. Donec tempor magna vel nibh interdum, in consectetur enim cursus. Nulla id porta ex, id tristique diam. Nunc fermentum libero a odio molestie hendrerit. Nulla sapien ipsum, tempor egestas lacus nec, iaculis mollis eros. Curabitur vel porttitor nunc. Aliquam ut metus pellentesque arcu aliquam tempor. Mauris dapibus maximus condimentum. Praesent urna orci, sodales sed scelerisque nec, commodo a velit. Duis id cursus lectus.
                            </p>
                        </div>
                        <div className='share'>
                            <p>Share Article</p>
                            <ul className="share-icons">
                                <li>
                                    <a href='/'>
                                        <img src={images.Facebook} alt="Facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href='/'>
                                        <img src={images.Instagram} alt="Instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href='/'>
                                        <img src={images.Twitter} alt="Twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href='/'>
                                        <img src={images.LinkedIn} alt="LinkedIn" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blog-bottom container'>
                <h2 className='section-title'>Other Blogs In the Category</h2>
                <div className='row'>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="blog-box">
                            <div class="blur-overlay"></div>
                            <img src={images.Blog1} alt="Blog1" />
                            <div className="blog-txt">
                                <p className="tagline">Sed sed dolor tempus sap
                                    ien lobortis auctor.</p>
                                <p className="date">March 11, 2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="blog-box">
                            <div class="blur-overlay"></div>
                            <img src={images.Blog2} alt="Blog2" />
                            <div className="blog-txt">
                                <p className="tagline">Integer ullamcorper erat in
                                    feugiat facilisis.</p>
                                <p className="date">Feb 28, 2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="blog-box">
                            <div class="blur-overlay"></div>
                            <img src={images.Blog3} alt="Blog1" />
                            <div className="blog-txt">
                                <p className="tagline">Nunc pretium sit amet tort
                                    or a imperdiet. Donec</p>
                                <p className="date">March 03, 2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="blog-box">
                            <div class="blur-overlay"></div>
                            <img src={images.Blog4} alt="Blog1" />
                            <div className="blog-txt">
                                <p className="tagline">Vestibulum a mattis metus
                                    ac vulputate risus.</p>
                                <p className="date">March 11, 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section >
    );
}

export default BlogSingle;