import React from "react";
import './Vision.css'
import images from "../../../Images";

const Vision = () => {
    return (
        <section id="Vision" className="vision vertical-space horizontal-space section-bg">
            <div class="blur-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="visiom-txt">
                            <h3>The Vision</h3>
                            <p>
                                SoApp's vision is to revolutionize the travel experience by empowering
                                the community to create and share memorable trips while providing
                                financial benefits to its users. In a world marked by echo chambers
                                and division, SoApp believes in the transformative power of travel
                                to bridge divides and foster empathy among people from diverse backgrounds.
                            </p>
                            <p>
                                One of the unique features of SoApp is its financial incentive model.
                                After a trip concludes, users have the opportunity to earn money by
                                selling the trip to other members of the community. This creates a
                                sustainable cycle where users can recoup some of their travel expenses
                                while also sharing their experiences with others.
                            </p>
                            <p>
                                By fostering a sense of community, enabling collaborative trip creation,
                                and providing financial benefits through trip sales, SoApp aims to
                                redefine the travel landscape and build a more inclusive and rewarding
                                travel community. Together, we can embark on journeys that not only
                                enrich our lives but also contribute to a more empathetic and
                                interconnected world.
                            </p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vision-img">
                            <img src={images.visionAabout} alt="Vision About" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Vision;