import React from 'react';
import images from '../../Images';
import './Pagination.css'
import { Button } from 'react-bootstrap';

const Pagination = ({ newsPerPage, totalNews, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalNews / newsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className='pagination'>
                {currentPage > 1 && (
                    <li className='page-item'>
                        <Button onClick={() => paginate(currentPage - 1)} className='page-link'>
                            <img src={images.PaginationPrev} alt="Prev" />
                        </Button>
                    </li>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <Button onClick={() => paginate(number)} className='page-link'>
                            {number}
                        </Button>
                    </li>
                ))}
                {currentPage < pageNumbers.length && (
                    <li className='page-item'>
                        <Button onClick={() => paginate(currentPage + 1)} className='page-link'>
                            <img src={images.PaginationNext} alt="Next" />
                        </Button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;
