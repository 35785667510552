import React from "react";
import './Footer.css';
import images from '../../Images';
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const location = useLocation();
    
    const getFootLinkClass = (path) => {
        return location.pathname === path ? 'foot-link active' : 'foot-link';
    };

    return (
        <section id='Footer' className='footer section-bg'>
            <div className="blur-overlay"></div>
            <div className='footer-inner horizontal-space'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-3">
                            <div className="footer-logo">
                                <Link to='/' onClick={scrollToTop}>
                                    <img src={images.logo2} alt="footer logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-3">
                            <ul className="footer-ul p-0">
                                <li><Link className={getFootLinkClass('/about')} to='/about' onClick={scrollToTop}>About</Link></li>
                                <li><Link className={getFootLinkClass('/future-features')} to='/future-features' onClick={scrollToTop}>Future Features</Link></li>
                                <li><Link className={getFootLinkClass('/testimonials')} to='/testimonials' onClick={scrollToTop}>Testimonials</Link></li>
                            </ul>
                        </div>
                        <div className="border-top mob-only"></div>
                        <div className="col-3">
                            <ul className="footer-ul">
                                <li><Link className={getFootLinkClass('/faq')} to='/faq' onClick={scrollToTop}>Help</Link></li>
                                <li><Link className={getFootLinkClass('/terms-of-use')} to='/terms-of-use' onClick={scrollToTop}>Terms of use</Link></li>
                                <li><Link className={getFootLinkClass('/gdpr')} to='/gdpr' onClick={scrollToTop}>GDPR</Link></li>
                            </ul>
                        </div>
                        <div className="border-top mob-only"></div>
                        <div className="col-3">
                            <ul className="footer-ul">
                                <li><Link className={getFootLinkClass('/blogs')} to='/blogs' onClick={scrollToTop}>Blogs</Link></li>
                                <li><Link className={getFootLinkClass('/support-us')} to='/support-us' onClick={scrollToTop}>Support Us</Link></li>
                                <li><Link className={getFootLinkClass('/contact-us')} to='/contact-us' onClick={scrollToTop}>Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row footer-bottom">
                        <div className="border-top mob-only"></div>
                        <div className="row footer-bottom-txt">
                            <div className="col-md-8 col-6 d-flex justify-content-start o-2">
                                <p>© 2024 SO App. All rights reserved. Powered by <a className="text-decoration-underline" href="https://www.hatsoffdigital.com/" target="_blank" rel="noreferrer">Hats-Off</a></p>
                            </div>
                            <div className="col-md-4 col-6 d-flex justify-content-end">
                                <ul className="social-icons">
                                    <li>
                                        <Link to='/' target='_blank'>
                                            <img src={images.Facebook} alt="Facebook" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/' target='_blank'>
                                            <img src={images.Instagram} alt="Instagram" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/' target='_blank'>
                                            <img src={images.Twitter} alt="Twitter" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/' target='_blank'>
                                            <img src={images.LinkedIn} alt="LinkedIn" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;