import React from "react";
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";

export default function Legal() {
    return (
        <>
            <BreadCrumb currentPage="Legal Notice" pageTitle="Maecenas in enim eget felis" />
            <section id="legal" className="horizontal-space vertical-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="legal-txt d-flex flex-column justify-content-center align-items-start">
                                <p>Aliquam consectetur dui vestibulum magna faucibus ullamcorper. Nulla semper egestas massa, euismod ornare nibh blandit quis. Maecenas lectus magna, maximus a rutrum in, facilisis sed augue. Donec condimentum luctus lectus, vel feugiat nisl tristique id. Mauris tempus orci mauris, tempor cursus erat efficitur nec. Sed non mollis neque, vel cursus odio. Fusce iaculis consectetur turpis, vitae semper mauris pharetra scelerisque. Donec sit amet sagittis lacus.</p>
                                <ul>
                                    <li>Duis convallis magna consequat enim posuere pulvinar.</li>
                                    <li>Ut non magna finibus, rhoncus metus vitae, molestie ligula.</li>
                                    <li>Mauris congue risus eget dui iaculis, nec scelerisque massa porttitor.</li>
                                    <li>Aenean quis diam sed quam ultricies laoreet.</li>
                                </ul>
                                <p>Donec vitae vehicula tellus. Aliquam euismod dictum ante a eleifend. Donec elementum arcu et dapibus laoreet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed condimentum ullamcorper condimentum. Mauris pretium condimentum sapien, lobortis suscipit mauris bibendum vitae. Aenean quam sem, consectetur ac nisl vitae, semper ornare mi. Sed nunc dui, feugiat ac mi vel, porttitor consequat massa. Aliquam pellentesque lorem at dolor placerat laoreet. Proin sit amet quam at velit volutpat imperdiet. Nullam bibendum velit arcu, eu tristique orci consectetur eu. In rhoncus consectetur nulla, a hendrerit sem eleifend eget. Quisque viverra neque massa, nec ultrices augue elementum at. Pellentesque tincidunt eleifend pellentesque. In hac habitasse platea dictumst. Nunc vitae enim ipsum.</p>
                                <p>Nunc eget nulla eget nulla tincidunt aliquet. Phasellus purus erat, laoreet ac placerat non, maximus quis libero. Ut et semper lacus. Proin fermentum libero imperdiet lacus fermentum viverra. Morbi eleifend elit a lobortis lacinia. Mauris faucibus nisl eget ex sodales mattis. Maecenas cursus venenatis luctus. Vivamus hendrerit purus eget varius aliquet. Cras interdum molestie sodales. Duis sit amet tellus ultricies metus condimentum vestibulum. Integer scelerisque, magna eu rhoncus mollis, leo odio dictum lectus, quis vehicula eros ligula sit amet neque. Nam vel neque a ex congue pharetra sed a risus. Nam consectetur sapien quis purus feugiat dictum. Duis lacus sem, varius sed ipsum sed, ornare vulputate sem. Sed sollicitudin ante non purus convallis, sed consequat elit porttitor.</p>
                                <ul>
                                    <li>Duis in urna vel ex vehicula faucibus vel non dolor.</li>
                                    <li>Nam porttitor nulla vel fringilla commodo.</li>
                                    <li>Phasellus vel lorem quis ante pharetra mollis ut nec felis.</li>
                                    <li>Suspendisse tempus justo sit amet turpis feugiat auctor.</li>
                                </ul>
                                <p>Fusce a rutrum tortor. Ut elit erat, fringilla a nibh ultrices, viverra varius nunc. Morbi tristique ornare velit, tincidunt sollicitudin massa hendrerit in. Duis suscipit, urna eu pellentesque rhoncus, libero libero euismod felis, a luctus purus metus sed mauris. Nam ut erat pretium, sagittis magna vitae, commodo sem. Pellentesque ultricies tempus lorem sit amet sollicitudin. Nulla non diam id sapien molestie pharetra at vel tellus.</p>
                                <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed ullamcorper cursus nulla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In hac habitasse platea dictumst. Maecenas pellentesque nisl sed erat vulputate, vel tempus sem auctor. Nunc laoreet consectetur maximus. Integer bibendum, dui eget vulputate mattis, ex sapien pretium ligula, ac tincidunt eros nisl eget libero. Sed justo leo, molestie a massa sed, tincidunt efficitur dolor. Integer non rutrum leo. Proin vitae purus sit amet eros porttitor malesuada ut ut massa. Nam sed porttitor odio. Nunc eget purus venenatis, pulvinar nunc sed, fringilla velit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}