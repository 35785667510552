import React, { useEffect, useState } from 'react';
import Welcome from './Welcome_Component/Welcome';
import Tester from './Tester/Tester';
import Vision from './Vision_Component/Vision';
import Launch from './Launch_Component/Launch';
import Feature from './Feature_Component/Feature';
import Testimonial from './Testimonial_Component/Testimonial';
import Blog from './Blog_Component/Blog';
import Store from './Store_Component/Store';
import Loader from '../../Loader';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const videos = document.querySelectorAll('video');

    const mediaPromises = [];

    images.forEach(image => {
      if (!image.complete) {
        mediaPromises.push(new Promise(resolve => {
          image.onload = image.onerror = resolve;
        }));
      }
    });

    videos.forEach(video => {
      if (!video.readyState === 4) {
        mediaPromises.push(new Promise(resolve => {
          video.onloadeddata = video.onerror = resolve;
        }));
      }
    });

    Promise.all(mediaPromises).then(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Welcome />
      <Tester />
      <Vision />
      <Launch />
      <Feature />
      <Testimonial />
      <Blog />
      <Store />
    </>
  );
};

export default Home;
