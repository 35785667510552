import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import Vision from "./Vision_Component/Vision";
import Pledge from "./Pledge_Component/Pledge";
// import WWA from "./WWA_Component/WWA";
import Team from "./Team_Component/Team";

const About = () => {
    return (
        <>
            <BreadCrumb currentPage="About Us" pageTitle="From Echo-chambers to Open horizons" />
            <Vision />
            <Pledge />
            {/* <WWA /> */}
            <Team />
        </>
    )
}

export default About;