import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Link, useLocation } from 'react-router-dom';
import './Welcome.css';
import images from '../../../Images';

const Welcome = () => {
    const titleDivRef = useRef(null);
    const welcomeInnerRef = useRef(null);
    const location = useLocation();

    const initAnimations = () => {
        const titleDiv = titleDivRef.current;
        const welcomeInner = welcomeInnerRef.current;

        // Ensure initial state of titleDiv
        gsap.set(titleDiv, {
            opacity: 1,
            scale: 1,
            display: 'flex',
        });

        // Animate titleDiv on scroll
        gsap.fromTo(titleDiv, {
            opacity: 1,
            scale: 1,
        }, {
            opacity: 0,
            scale: 0.75,
            display: 'none',
            scrollTrigger: {
                trigger: welcomeInner,
                start: 'top bottom',
                end: 'top 80%',
                scrub: 1,
            }
        });

        // Animate welcomeInner on scroll
        gsap.fromTo(welcomeInner, {
            y: '100%',
        }, {
            y: '0%',
            scrollTrigger: {
                trigger: welcomeInner,
                start: 'top bottom',
                end: 'top top',
                scrub: 1,
            }
        });
    };

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        // Initialize animations on mount
        initAnimations();

        // Clean up animations on component unmount
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        if (location.pathname === '/') {
            // Reset animations when navigating back to this component
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            initAnimations();
        }
    }, [location]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <section id='Welcome' className='welcome vertical-space-2 horizontal-space'>
            <div className='titleDiv' ref={titleDivRef}>
                <h2 className='section-title-2'>Welcome, To SoApp</h2>
                <p className='sub-title'>Group, as one</p>
                <div className='my-flex-center gap-3'>
                    <a href='/' target='_blank'><img src={images.playstore2} alt='playstore2' /></a>
                    <a href='/' target='_blank'><img src={images.applestore2} alt='applestore2' /></a>
                </div>
                <div className='my-flex-center mt-3'>
                    <Link to='/support-us' onClick={scrollToTop} className='more-btn'>Support Us</Link>
                </div>
            </div>
            <div className='welcome-inner' ref={welcomeInnerRef}>
                <div className='my-flex-center welcome-img'>
                    <video autoPlay loop muted>
                        <source src={images.Flip} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    )
}

export default Welcome;
