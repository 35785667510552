import React from 'react';
import './Store.css';
import images from "../../../Images";


const Store = () => {

    return (
        <section id='Store' className='store vertical-space horizontal-space'>
            <div className='store-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='store-box section-bg inner-padding mr'>
                                <div class="blur-overlay"></div>
                                <img src={images.playstore3} alt='PlayStore3' />
                                <h2 className='section-title'>Play Store</h2>
                                <p className='vision-text'>
                                    SIngle subscription for all your devices
                                </p>
                                <div className='divider'></div>
                                {/* <h2 className='section-title-2'>$19.99/Year</h2> */}
                                <div className='btn-outer'>
                                    <a href='/' target='_blank' className='more-btn'>Get An App</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='store-box section-bg inner-padding ml'>
                                <div class="blur-overlay"></div>
                                <img src={images.applestore3} alt='AppleStore3' />
                                <h2 className='section-title'>App Store</h2>
                                <p className='vision-text'>
                                    SIngle subscription for all your devices
                                </p>
                                <div className='divider'></div>
                                {/* <h2 className='section-title-2'>$29.99/Year</h2> */}
                                <div className='btn-outer'>
                                    <a href='/' target='_blank' className='more-btn'>Get An App</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Store;