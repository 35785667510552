import React from "react";
import './BreadCrumb.css'

const BreadCrumb = ({ currentPage, pageTitle, }) => {
    return (
        <section className="bread_crumb horizontal-space">
            <div className="breadcrumb_current my-flex-center">
                <p>{currentPage}</p>
            </div>
            <div className="my-flex-center">
                <h2 className="section-title">{pageTitle}</h2>
            </div>
        </section>
    )
}

export default BreadCrumb;