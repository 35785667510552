import React, { useState } from "react";
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import './Blogs.css';
import images from '../../Images'
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../Pagination_Component/Pagination";

const Blogs = () => {
    const [category, setCategory] = useState('all');
    const [currentPages, setCurrentPages] = useState({
        all: 1,
        categoy1: 1,
        categoy2: 1,
        categoy3: 1,
    });
    const newsPerPage = 8;

    const newsData = Array.from({ length: 32 }, (_, i) => ({
        id: i + 1,
        imageUrl: images.BlogMain1, 
        category: 'categoy1',
        title: `News Title ${i + 1}`,
        date: `May ${i + 1}, 2024`
    }));
    
    
    const sortedNewsData = newsData.sort((a, b) => b.id - a.id);

    const filteredNews = category === 'all' ? sortedNewsData : sortedNewsData.filter(news => news.category === category);

    const indexOfLastNews = currentPages[category] * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = filteredNews.slice(indexOfFirstNews, indexOfLastNews);

    const paginate = (pageNumber) => {
        setCurrentPages({
            ...currentPages,
            [category]: pageNumber,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleTabClick = (tab) => {
        setCategory(tab);
        setCurrentPages({
            ...currentPages,
            [tab]: 1,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Render news items
    const renderNewsItems = () => {
        return currentNews.map((newsItem) => (
            <div key={newsItem.id} className="col-lg-3 col-md-4 col-sm-6 col-6">
                <Link to='../blog-single' className="news-box">
                    <div className="blur-overlay"></div>
                    <div className="news-img">
                        <img src={newsItem.imageUrl} alt="newsimg" />
                    </div>
                    <div className="news-details">
                        <p className="category">{newsItem.category}</p>
                        <h3 className="title">{newsItem.title}</h3>
                        <p className="date">{newsItem.date}</p>
                    </div>
                </Link>
            </div>
        ));
    };

    return (
        <>
            <BreadCrumb currentPage="Blogs" pageTitle="“Ink & Insights: Our Blog Corner”" />
            <section id="Blogs" className="Blog horizontal-space vertical-space">
                <div className="container">
                    <div className="category-menu">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Nav variant="pills" activeKey={category}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="all" onClick={() => handleTabClick('all')}> All </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="categoy1" onClick={() => handleTabClick('categoy1')}>Group Management</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="categoy2" onClick={() => handleTabClick('categoy2')}>Travelling+Trust</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="categoy3" onClick={() => handleTabClick('categoy3')}>Data Remuneration</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>

                                <div className='col-12'>
                                    <div className="news-container">
                                        <div className="row">
                                            {renderNewsItems()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Pagination
                            newsPerPage={newsPerPage}
                            totalNews={filteredNews.length}
                            paginate={paginate}
                            currentPage={currentPages[category]}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogs;
