import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import images from "../../../Images";
import './Tester.css';
import '../PreLaunch_Component/PreLaunch.css';

export default function Tester() {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const swiperRef = useRef(null);
    const titleDivRef = useRef(null);
    const testerInnerRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray('.cards-split').forEach(card => {
            ScrollTrigger.create({
                trigger: card,
                start: 'top 80%',
                end: 'bottom 50%',
                onEnter: () => {
                    card.classList.add('transition');
                },
                onLeaveBack: () => {
                    card.classList.remove('transition');
                },
                onLeave: () => {
                    if (swiperInstance) {
                        swiperInstance.slideTo(0);
                    }
                },
            });
        });

        gsap.fromTo(titleDivRef.current, {
            opacity: 0,
            y: 150,
        }, {
            opacity: 1,
            y: 0,
            duration: 1,
            scrollTrigger: {
                trigger: titleDivRef.current,
                start: 'top 85%',
                end: 'top 60%',
                toggleActions: 'play none none reverse',
                scrub: 0.5,
            }
        });
    }, [swiperInstance]);

    // Refs for each video element
    const videoRefs = useRef([]);

    useEffect(() => {
        videoRefs.current.forEach((video) => {
            if (video) {
                const handleEnded = () => {
                    video.currentTime = 0;
                    video.play();
                };

                video.addEventListener('ended', handleEnded);

                return () => {
                    video.removeEventListener('ended', handleEnded);
                };
            }
        });
    }, []);

    return (
        <div id="tester" className="carousel-container vertical-space">
            <div className='titleDiv' ref={titleDivRef}>
                <h2 className='section-title horizontal-space'>Pre-Launch</h2>
                <p className='sub-title'>All the Upcoming Features</p>
            </div>
            <div className='tester-inner' ref={testerInnerRef}>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={150}
                    // slidesPerView={2.1}
                    breakpoints={{
                        640: {
                            slidesPerView: 1.1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 1.5,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 1.5,
                            spaceBetween: 50,
                        },
                        1200: {
                            slidesPerView: 1.7,
                            spaceBetween: 50,
                        },
                        1300: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        }
                    }}
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<span class="${className}">0${index + 1}</span>`;
                        },
                    }}
                    scrollbar={{ draggable: true }}
                    onSwiper={setSwiperInstance}
                    ref={swiperRef}
                    onSlideChange={() => {
                        if (swiperInstance) {
                            swiperInstance.pagination.render();
                            swiperInstance.pagination.update();
                        }
                    }}
                    className='cards-split'
                >
                    <SwiperSlide>
                        <ul className="cards-split1">
                            <li className="card card-1">
                                <div id='No1' className="prelaunch-slide">
                                    <div className="prelaunch-box">
                                        <div className="blur-overlay"></div>
                                        <div className="row">
                                            <div className="col-6 br-p my-flex-center">
                                                <div className="prelaunch-txt">
                                                    <h2 className="section-title-2">
                                                        Main wall & chat wall
                                                    </h2>
                                                    <p>
                                                        SoApp’s main wall allows for a brief overview
                                                        of all your activities at once!
                                                        The chat wall allows you to better navigate a
                                                        particularly busy group.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="tester-vid">
                                                    <video autoPlay ref={(el) => videoRefs.current[0] = el} muted>
                                                        <source src={images.GroupChatVid} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </SwiperSlide>
                    <SwiperSlide>
                        <ul className="cards-split1">
                            <li className="card card-2">
                                <div id='No2' className="prelaunch-slide">
                                    <div className="prelaunch-box">
                                        <div className="blur-overlay"></div>
                                        <div className="row">
                                            <div className="col-6 br-p my-flex-center">
                                                <div className="prelaunch-txt">
                                                    <h2 className="section-title-2">
                                                        Group chat suites
                                                    </h2>
                                                    <p>
                                                        SoApp’s Chats come with built-in tools
                                                        to optimise your group’s organisation
                                                        from projects to trips to memes and jokes!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="tester-vid">
                                                    <video autoPlay ref={(el) => videoRefs.current[1] = el} muted>
                                                        <source src={images.MainWall} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </SwiperSlide>
                    <SwiperSlide>
                        <ul className="cards-split1">
                            <li className="card card-3">
                                <div id='No3' className="prelaunch-slide">
                                    <div className="prelaunch-box">
                                        <div className="blur-overlay"></div>
                                        <div className="row">
                                            <div className="col-6 br-p my-flex-center">
                                                <div className="prelaunch-txt">
                                                    <h2 className="section-title-2">
                                                        Pinned messaging
                                                    </h2>
                                                    <p>
                                                        Never Missout of
                                                        Information
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="tester-vid">
                                                    <video autoPlay ref={(el) => videoRefs.current[2] = el} muted>
                                                        <source src={images.PinnedMessage} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </SwiperSlide>
                    <SwiperSlide>
                        <ul className="cards-split1">
                            <li className="card card-4">
                                <div id='No4' className="prelaunch-slide">
                                    <div className="prelaunch-box">
                                        <div class="blur-overlay"></div>
                                        <div className="row">
                                            <div className="col-6 br-p my-flex-center">
                                                <div className="prelaunch-txt">
                                                    <h2 className="section-title-2">
                                                        Complex messaging
                                                    </h2>
                                                    <p>
                                                        Curate A message with pics, text, vocals,
                                                        music and more!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="tester-vid">
                                                    <video autoPlay ref={(el) => videoRefs.current[3] = el} muted>
                                                        <source src={images.ComplexMessage} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </SwiperSlide>
                </Swiper>
                <div className='myPagination'>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </div >
    );
}
