import React from "react";
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import './Features.css';
import images from "../../Images";

const Features = () => {
    return (
        <>
            <BreadCrumb currentPage="Features" pageTitle="SoApp’s future, Together" />
            <section id="Features" className="features horizontal-space vertical-space">
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <h2 className="section-title">Money Talks</h2>
                            <p className="sub-title">
                                Soapp enables it’s user to share the costs through the group chat…..the group pays all at once. Soapp uses NFC technology and instant money sharing
                            </p>
                        </div>
                        <div className="col-5">
                            <div className="feature-img">
                                <div class="blur-overlay"></div>
                                <img src={images.moneyTalks} alt="Feature" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <div className="feature-img">
                                <div class="blur-overlay"></div>
                                <img src={images.chatApi} alt="Feature" />
                            </div>
                        </div>
                        <div className="col-7">
                            <h2 className="section-title">Chat API</h2>
                            <p className="sub-title">
                                Leverage SoApp’s complete chat functionalities to give your customers the best chat experience they could ever wish for!
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">
                            <h2 className="section-title">SO Create</h2>
                            <p className="sub-title">
                                SoApp encourages its users to go and experience adventures with their friends, and once they are back, the trip can be set up on a marketplace for anyone who might want to enjoy the same adventure. – covering most things like transportation, hotels, activities etc…
                            </p>
                        </div>
                        <div className="col-5">
                            <div className="feature-img">
                                <div class="blur-overlay"></div>
                                <img src={images.soCreate} alt="Feature" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <div className="feature-img">
                                <div class="blur-overlay"></div>
                                <img src={images.gdprDataClones} alt="Feature" />
                            </div>
                        </div>
                        <div className="col-7">
                            <h2 className="section-title">GDPR & Data Clones</h2>
                            <p className="sub-title">
                                SoApp strives to serve its users and make their lives easy by making each user able to mint a clone of their data which can then be sold or gifted on the SoApp dataclone market place. This can enable other members of the community to experience SoApp through another person’s Point Of View!

                                Practical cases range from curiosity to research to custom and tailor made news feeds based on real people or fictional characters!

                                A revolutionary new way to use social media and to earn by giving back to the community.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">
                            <h2 className="section-title">SO Press</h2>
                            <p className="sub-title">
                                SoApp wants to ensure that you do NOT stay stuck in an echo chamber and will strive to make the information and news stories on SoApp as contextualised as possible, and keep you informed through a balanced diet from diferent sources to stay as informed as possible.
                            </p>
                        </div>
                        <div className="col-5">
                            <div className="feature-img">
                                <div class="blur-overlay"></div>
                                <img src={images.soPress} alt="Feature" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features;