import React from "react";
import './Team.css';
import images from "../../../Images";

const Team = () => {
    return (
        <section id="Team" className="team vertical-space horizontal-space">
            <div className='titleDiv'>
                <h2 className='section-title'>Our Team</h2>
                <p className='sub-title'>SoApp is made up from passionate people who are on a mission to prove that the "impossible" can be done.</p>
            </div>
            <div className="team-inner">
                <div className="container">
                    <div className="row b-mar">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box r-mar">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team1} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Josselin</p>
                                    <p className="team-title">Co-Founder &  CEO</p>
                                    <p className="team-social">
                                        <a href="/" target="_blank"><img src={images.LinkedinBlue} alt="team-social" /></a>
                                        <a href="/" target="_blank"><img src={images.TwitterBlue} alt="team-social" /></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box mid-margin">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team2} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Augustin</p>
                                    <p className="team-title">CTO & Co-founder</p>
                                    <p className="team-social">
                                        <img src={images.LinkedinBlue} alt="team-social" />
                                        <img src={images.TwitterBlue} alt="team-social" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box l-mar">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team3} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Raphael</p>
                                    <p className="team-title">COO</p>
                                    <p className="team-social">
                                        <img src={images.LinkedinBlue} alt="team-social" />
                                        <img src={images.TwitterBlue} alt="team-social" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="row"> */}
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box r-mar">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team1} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Josselin</p>
                                    <p className="team-title">Co-Founder &  CEO</p>
                                    <p className="team-social">
                                        <img src={images.LinkedinBlue} alt="team-social" />
                                        <img src={images.TwitterBlue} alt="team-social" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box mid-margin">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team2} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Augustin</p>
                                    <p className="team-title">CTO & Co-founder</p>
                                    <p className="team-social">
                                        <img src={images.LinkedinBlue} alt="team-social" />
                                        <img src={images.TwitterBlue} alt="team-social" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-box l-mar">
                                <div class="blur-overlay"></div>
                                <div className="team-img">
                                    <img src={images.Team3} alt="team" />
                                </div>
                                <div className="team-txt">
                                    <p className="team-name">Raphael</p>
                                    <p className="team-title">COO</p>
                                    <p className="team-social">
                                        <img src={images.LinkedinBlue} alt="team-social" />
                                        <img src={images.TwitterBlue} alt="team-social" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team;