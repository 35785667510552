import React, { useState, useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import './Launch.css';
import images from "../../../Images";

const Launch = () => {
    const [activeTab, setActiveTab] = useState('home');
    const videoRef = useRef(null);

    useEffect(() => {
        const currentVideoRef = videoRef.current;

        if (currentVideoRef) {
            const handleEnded = () => {
                currentVideoRef.currentTime = 0;
                currentVideoRef.play();
            };

            currentVideoRef.addEventListener('ended', handleEnded);

            return () => {
                currentVideoRef.removeEventListener('ended', handleEnded);
            };
        }
    }, [activeTab]);

    const renderContent = () => {
        const contentSource = {
            home: { type: 'video', src: images.VersatileChat },
            about: { type: 'image', src: images.LaunchBG },
            contact: { type: 'video', src: images.EventOrganisation }
        };

        const { type, src } = contentSource[activeTab];

        if (type === 'video') {
            return (
                <div className="launch-vid">
                    <video key={activeTab} autoPlay loop ref={videoRef} muted>
                        <source src={src} type="video/mp4" />
                    </video>
                </div>
            );
        } else if (type === 'image') {
            return (
                <div className="launch-vid">
                    <img src={src} alt={`${activeTab} content`} />
                </div>
            );
        }
    };

    return (
        <section id='Launch' className='launch inner-padding vertical-space horizontal-space section-bg'>
            <div className="blur-overlay"></div>
            <div className='titleDiv'>
                <h2 className='section-title'>Launch</h2>
                <p className='sub-title'>What you can expect at Launch</p>
            </div>
            <div className='launch-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-6 d-flex align-items-center justify-content-center'>
                            <Nav variant="pills" defaultActiveKey="home">
                                <Nav.Item>
                                    <Nav.Link eventKey="home" onClick={() => setActiveTab('home')}>
                                        <h2 className='launch-h2'>Versatile Chat suites</h2>
                                        <p className='launch-p'>SoApp’s Chats Suites come with many options such as: Geofencing,
                                            paywalls, timers and more!</p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="about" onClick={() => setActiveTab('about')}>
                                        <h2 className='launch-h2'>Group payments</h2>
                                        <p className='launch-p'>Through SoApp’s groups you can pool your resources together and enjoy your
                                            holidays without burdening a single member!</p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="contact" onClick={() => setActiveTab('contact')}>
                                        <h2 className='launch-h2'>Event Organisation</h2>
                                        <p className='launch-p'>Organizing events is always a hassle, SoApp allows you to create and organise
                                            an event or trip directly within your group!</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Launch;
