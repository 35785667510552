import React, { useEffect, useState } from 'react';
import './Header.css';
import images from '../../Images';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      const threshold = viewportHeight * 0;

      if (currentScroll > lastScrollTop && currentScroll > threshold) {
        setNavbarVisible(false);
      } else {
        setNavbarVisible(true);
      }

      if (currentScroll > threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      setLastScrollTop(currentScroll);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    scrollToTop();
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  return (
    <header>
      <nav id='navbar' className={`navbar navbar-expand-lg ${navbarVisible ? 'visible' : 'hidden'} ${isFixed ? 'fixed' : ''}`}>
        <div className="container-fluid justify-content-around">
          <div className='logo-div col-4 d-flex justify-content-center'>
            <Link className="navbar-brand" to="/">
              <img src={images.logo} alt='logo' onClick={scrollToTop} />
            </Link>
          </div>
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <img src={isMenuOpen ? images.Close : images.Menu} className="mobile-menu-icon" alt="menu-icon" />
          </button>
          <div className={`collapse navbar-collapse col-8 ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ml-auto w-100 justify-content-center">
              <li className="nav-item">
                <Link className={getNavLinkClass('/future-features')} to="/future-features" onClick={handleLinkClick}>Future Features</Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/about')} to="/about" onClick={handleLinkClick}>About Us</Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/testimonials')} to="/testimonials" onClick={handleLinkClick}><s>Testimonials</s></Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/blogs')} to="/blogs" onClick={handleLinkClick}><s>Blogs</s></Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/contact-us')} to="/contact-us" onClick={handleLinkClick}>Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/support-us')} to="/support-us" onClick={handleLinkClick}>Support Us</Link>
              </li>
              <li className="nav-item d-flex">
                <Link className="nav-link" to="/" target='_blank'>
                  <img src={images.playstore} alt='PlayStore' onClick={handleLinkClick} />
                </Link>
                <Link className="nav-link" to="/" target='_blank'>
                  <img src={images.applestore} alt='AppleStore' onClick={handleLinkClick} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
