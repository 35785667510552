import React from "react";
import './Pledge.css';

const Pledge = () => {
    return (
        <section id="Pledge" className="pledge vertical-space horizontal-space">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="pledge-txt">
                            <h2 className="section-title">Our Pledge</h2>
                            <ul>
                                <li>We are dedicated to making event planning and group organization simpler for our users so you can spend more time enjoying your friends and LESS time checking your socials!</li>
                                <li>We pledge to empower our community to LIVE and share unforgettable travel experiences.</li>
                                <li>We promise to provide financial benefits to our users through innovative trip-sharing opportunities.</li>
                                <li>Together, we will redefine the travel landscape and build a more creative and rewarding global community.</li>
                                <li>Join us in our journey to explore, connect, and make a positive impact on the world with SoApp</li>
                                <li>LIVE MORE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pledge;