import './Blog.css';
import images from "../../../Images";
import { Link } from "react-router-dom";

const Blog = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };


    return (
        <section id='Blog' className='blog vertical-space horizontal-space'>
            <div className='titleDiv'>
                <h2 className='section-title'>See Our Latest Blogs</h2>
                <p className='sub-title'>Integer vehicula placerat felis, ac convallis est molestie id.</p>
            </div>
            <div className='blog-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-3">
                            <Link to='/blogs' onClick={scrollToTop} className="blog-box">
                                <div class="blur-overlay"></div>
                                <img src={images.Blog1} alt="Blog1" />
                                <div className="blog-txt">
                                    <p className="tagline">Sed sed dolor tempus sap
                                        ien lobortis auctor.</p>
                                    <p className="date">March 11, 2024</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-3">
                            <div className="mtc"></div>
                            <Link to='/blogs' onClick={scrollToTop} className="blog-box">
                                <div class="blur-overlay"></div>
                                <img src={images.Blog2} alt="Blog2" />
                                <div className="blog-txt">
                                    <p className="tagline">Integer ullamcorper erat in
                                        feugiat facilisis.</p>
                                    <p className="date">Feb 28, 2024</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-3">
                            <div className="mtc"></div>
                            <Link to='/blogs' onClick={scrollToTop} className="blog-box">
                                <div class="blur-overlay"></div>
                                <img src={images.Blog3} alt="Blog1" />
                                <div className="blog-txt">
                                    <p className="tagline">Nunc pretium sit amet tort
                                        or a imperdiet. Donec</p>
                                    <p className="date">March 03, 2024</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-3">
                            <Link to='/blogs' onClick={scrollToTop} className="blog-box">
                                <div class="blur-overlay"></div>
                                <img src={images.Blog4} alt="Blog1" />
                                <div className="blog-txt">
                                    <p className="tagline">Vestibulum a mattis metus
                                        ac vulputate risus.</p>
                                    <p className="date">March 11, 2024</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="btn-mt">
                        <Link to='/Blogs' onClick={scrollToTop} className='more-btn'>View More</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;