import React from 'react';
import './Vision.css';
import images from "../../../Images";
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

const Vision = () => {
    const titleDivRef = useRef(null);
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        gsap.fromTo(titleDivRef.current, {
            opacity: 0,
            y: 50,
        }, {
            opacity: 1,
            y: 0,
            duration: 1,
            scrollTrigger: {
                trigger: titleDivRef.current,
                start: 'top 85%',
                end: 'top 60%',
                toggleActions: 'play none none reverse',
                scrub: 0.5,
            }
        });
    }, []);

    return (
        <section id='Vision' className='vision vertical-space horizontal-space'>
            <div className='titleDiv' ref={titleDivRef}>
                <h2 className='section-title'>Our Vision</h2>
                <p className='sub-title'>From echo-chambers to open horizons</p>
            </div>
            <div className='vision-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='vision-left'>
                                <img src={images.Vision} alt='vision' />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='vision-right section-bg inner-padding'>
                                <div class="blur-overlay"></div>
                                <p className='vision-text'>
                                    SoApp aims to give its users the tools to break free from their
                                    social media powered echo chambers by enabling people to not only
                                    view other people’s experiences, but to LIVE THEM. SoApp’s aim is
                                    to transform our world of echo-chambers into one of open horizons.
                                </p>
                                <Link to='/about' onClick={scrollToTop} className='more-btn'>Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Vision;