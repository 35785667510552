import React, { useState } from "react";
import './Faq.css';
import BreadCrumb from "../Breadcrumb_Component/BreadCrumb";
import images from "../../Images";

const Faq = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionToggle = (index) => {
        setActiveAccordion(index === activeAccordion ? null : index);
    };

    const isAccordionActive = (index) => {
        return index === activeAccordion;
    };

    return (
        <>
            <BreadCrumb currentPage="FAQ's" pageTitle="Frequently Asked Question’s" />
            <section id="Faq" className="faq horizontal-space vertical-space">
                <div className="container">
                    <div className="row">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={isAccordionActive(0)} aria-controls="collapseOne" onClick={() => handleAccordionToggle(0)}>
                                        <img src={isAccordionActive(0) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        What does LOREM mean?
                                    </button>
                                </h2>
                                <div id="collapseOne" className={`accordion-collapse collapse ${isAccordionActive(0) ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={isAccordionActive(1)} aria-controls="collapseTwo" onClick={() => handleAccordionToggle(1)}>
                                        <img src={isAccordionActive(1) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Where can I subscribe to your newsletter?
                                    </button>
                                </h2>
                                <div id="collapseTwo" className={`accordion-collapse collapse ${isAccordionActive(1) ? 'show' : ''}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={isAccordionActive(2)} aria-controls="collapseThree" onClick={() => handleAccordionToggle(2)}>
                                        <img src={isAccordionActive(2) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Where can I edit my billing and shipping address?
                                    </button>
                                </h2>
                                <div id="collapseThree" className={`accordion-collapse collapse ${isAccordionActive(2) ? 'show' : ''}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded={isAccordionActive(3)} aria-controls="collapseFour" onClick={() => handleAccordionToggle(3)}>
                                        <img src={isAccordionActive(3) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Can I order a free sample copy of a magazine?
                                    </button>
                                </h2>
                                <div id="collapseFour" className={`accordion-collapse collapse ${isAccordionActive(3) ? 'show' : ''}`} aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded={isAccordionActive(4)} aria-controls="collapseFive" onClick={() => handleAccordionToggle(4)}>
                                        <img src={isAccordionActive(4) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Are unsold magazines sent back to the publisher?
                                    </button>
                                </h2>
                                <div id="collapseFive" className={`accordion-collapse collapse ${isAccordionActive(4) ? 'show' : ''}`} aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded={isAccordionActive(5)} aria-controls="collapseSix" onClick={() => handleAccordionToggle(5)}>
                                        <img src={isAccordionActive(5) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Where can I find your disclaimer and privacy policy?
                                    </button>
                                </h2>
                                <div id="collapseSix" className={`accordion-collapse collapse ${isAccordionActive(5) ? 'show' : ''}`} aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded={isAccordionActive(6)} aria-controls="collapseSeven" onClick={() => handleAccordionToggle(6)}>
                                        <img src={isAccordionActive(6) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Can I change the displayed currency?
                                    </button>
                                </h2>
                                <div id="collapseSeven" className={`accordion-collapse collapse ${isAccordionActive(6) ? 'show' : ''}`} aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingEight">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded={isAccordionActive(7)} aria-controls="collapseEight" onClick={() => handleAccordionToggle(7)}>
                                        <img src={isAccordionActive(7) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Do I have the right to return an item?
                                    </button>
                                </h2>
                                <div id="collapseEight" className={`accordion-collapse collapse ${isAccordionActive(7) ? 'show' : ''}`} aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingNine">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded={isAccordionActive(8)} aria-controls="collapseNine" onClick={() => handleAccordionToggle(8)}>
                                        <img src={isAccordionActive(8) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Are you on Twitter, Facebook and other social media platforms?
                                    </button>
                                </h2>
                                <div id="collapseNine" className={`accordion-collapse collapse ${isAccordionActive(8) ? 'show' : ''}`} aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTen">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded={isAccordionActive(9)} aria-controls="collapseTen" onClick={() => handleAccordionToggle(9)}>
                                        <img src={isAccordionActive(9) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Where do I find your physical store and what are your opening hours?
                                    </button>
                                </h2>
                                <div id="collapseTen" className={`accordion-collapse collapse ${isAccordionActive(9) ? 'show' : ''}`} aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header no-bb" id="headingEleven">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded={isAccordionActive(10)} aria-controls="collapseEleven" onClick={() => handleAccordionToggle(10)}>
                                        <img src={isAccordionActive(10) ? images.Minus : images.Plus} className="accordion-icon" alt="accordion-icon" />
                                        Do you accept orders via Phone or E-mail?
                                    </button>
                                </h2>
                                <div id="collapseEleven" className={`accordion-collapse collapse ${isAccordionActive(10) ? 'show' : ''}`} aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                    <div className="accordion-body no-bb">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus turpis nec eros bibendum, eget scelerisque mauris convallis. Nulla at mi sed leo ultricies sagittis. Fusce auctor augue eu nunc efficitur, vitae convallis risus fringilla. Donec et augue at libero bibendum accumsan. Nullam non justo sit amet metus consequat interdum.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row hmq section-bg inner-padding">
                        <div class="blur-overlay"></div>
                        <div className="col-9">
                            <div className="hmqTxt">
                                <h2 className="section-title">Have More Questions?</h2>
                                <p className="sub-title">SO is constantly evolving and we’re here
                                    to help along the way. If you have additional questions, feel free
                                    to reach out.</p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="hmqBtn">
                                <a href='/ContactUs' className='more-btn'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq;
